@import '~antd/lib/style/themes/default.less';

:local {
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    // background-color: #eceeef;
    border-radius: 0.3rem;
  }

  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.1;
  }

  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  @media (min-width: @screen-sm-min) {
    .jumbotron {
      padding: 4rem 8rem;
    }
  }
}
