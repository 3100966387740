:local {
  .smart-table-search-form {
    :global {
      .ant-form-item {
        &:last-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}
