@footerHeight: 94px;
@mainPadding: 48px;
@boarderWidth: 1px * 2;
@tabsHeight: 58px;

:local {
  .full-screen-panel {
    &:global(.ant-card) > :global(.ant-card-body) {
      min-height: calc(100vh - @footerHeight - @mainPadding);
    }

    &:global(.ant-card-bordered) > :global(.ant-card-body) {
      min-height: calc(100vh - @footerHeight - @mainPadding - @boarderWidth);
    }

    &:global(.ant-card):global(.ant-card-contain-tabs)
      > :global(.ant-card-body) {
      min-height: calc(100vh - @footerHeight - @mainPadding - @tabsHeight);
    }
  }
}
