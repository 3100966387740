:local {
  .logo-loading {
    position: relative;
    width: 80px;
    height: 80px;

    &-logo {
      content: ' ';
      position: relative;
      height: 100%;
      background: center / contain url('../../assets/logo.png') no-repeat;
      animation: bounce 0.5s ease-out 0.1s infinite alternate;
      z-index: 10;
    }

    &-shadown {
      content: ' ';
      height: 10px;
      margin: -5px -5px 0;
      border-radius: 50%;
      background: rgb(0 0 0 / 20%);
      animation: scale 0.5s ease-out 0.1s infinite alternate;
    }
  }

  @keyframes bounce {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100px);
    }
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.3);
    }
  }
}
