@import '~antd/lib/style/themes/default.less';

:local {
  .link-button {
    background-color: transparent;
    color: @primary-color;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    transition: color 0.3s;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &[disabled] {
      color: @disabled-color;
    }
  }
}
