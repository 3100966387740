:local {
  .advanced-search-form {
    :global {
      // 兼容旧版本 Form
      .ant-legacy-form-item {
        display: flex;

        &-control-wrapper {
          flex: 1;
        }
      }
    }
  }
}
