:local {
  .footer-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    min-height: 64px;
    padding: 0 24px;
    line-height: 44px;
    background: #f6f7fa;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -12px 48px 16px rgb(0 0 0 / 3%);
    transition: width 0.2s ease; // asme as `.ant-layout-sider`
  }
}
