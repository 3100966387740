:local {
  .total-count {
    display: inline-block;

    &-title {
      font-size: 1em;
    }

    &-value {
      font-size: 1.4em;
    }
  }
}
