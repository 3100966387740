@import '~antd/lib/style/themes/default.less';

:local {
  .advanced-search-form-panel {
    padding: @padding-md @padding-lg;
    background: #fbfbfb;
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base;
    margin-bottom: @padding-sm;
  }
}
