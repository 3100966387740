@import '~antd/lib/style/themes/default.less';

:local {
  @classprefix: ~'margin-bottom';
  .@{classprefix}-sm {
    margin-bottom: @padding-sm;
  }
  .@{classprefix}-md {
    margin-bottom: @padding-md;
  }
  .@{classprefix}-lg {
    margin-bottom: @padding-lg;
  }
}
