@import '~antd/lib/style/themes/default.less';

:local {
  .smart-table-toolbar {
    align-items: baseline;
    margin-bottom: @padding-sm; /* margin in component ??? */

    &-left {
      button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
