@import '~antd/lib/style/themes/default.less';

:local {
  .page-header-layout {
    margin: -24px -40px;
    background: @layout-body-background;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content {
    margin: 24px 24px 0;
    flex: 1;
  }

  @media screen and (max-width: @screen-sm) {
    .content {
      margin: 24px 0 0;
    }
  }
}
