@import '~antd/lib/style/themes/default.less';

:local {
  .page-header {
    background: @component-background;
    padding: 16px 32px;
    border-bottom: @border-width-base @border-style-base @border-color-split;

    .breadcrumb {
      margin-bottom: 16px;
    }

    .tabs {
      :global {
        .ant-tabs-bar {
          border-bottom: @border-width-base @border-style-base
            @border-color-split;
        }
      }
    }
  }
}
